export default {
  colors: {
    dark: '#000000', // black
    bright: '#ffffff', // white

    // GREY
    greyPrimary: '#1C1D22',
    greySecondary: '#A59E92',
    greyTertiary: '#6B6760',
    greyQuaternary: '#393939',
    greyQuintary: '#C7C7C7',
    greySenary: '#9FA2A2',
    greySeptenary: '#191919',
    // greyOctonary: '#9FA2A2',
    // greyNonary: '#9FA2A2',
    // greyDenary: '#9FA2A2',

    // YELLOW
    yellowPrimary: '#F4E200',
    yellowSecondary: '#FCFDE8',

    // RED
    redPrimary: 'red',

    // BLUE
    bluePrimary: '#0D213F',
    blueSecondary: '#E8F7FD',

    // GREEN
    greenPrimary: '#008281',
    greenSecondary: '#3ECE8D',

    // CYAN
    cyanPrimary: 'cyan',

    // ORANGE
    orangePrimary: '#e96232',
    orangeSecondary: '#f60',

    // PURPLE
    purplePrimary: 'purple',

    // PINK
    pinkPrimary: '#FFA3BD',

    // Top Title
    topTitle: 'greySecondary',

    // MODES
    modes: {
      dark: {
        text: 'colors.bright',
        background: 'colors.dark',
      },
      bright: {
        text: 'colors.dark',
        background: 'colors.bright',
      },
    },
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  fonts: {
    body: '"Open Sans", sans-serif',
    heading: '"Open Sans", sans-serif',
    monospace: 'Menlo, monospace',
  },
  sizes: {
    size1: 2,
    size2: 3,
    size3: 4,
    size4: 6,
    size5: 8,
    size6: 10, // base metric power of 10
    size7: 12,
    size8: 18,
    size9: 20, // base spacing number magic 24
    size10: 24,
    size11: 30,
    size12: 32,
    size13: 36,
    size14: 40,
    size15: 48,
    size16: 50,
    size17: 56,
    size18: 60,
    size19: 64,
    size20: 70,
    size21: 72,
    size22: 80,
    size23: 90,
    size24: 96,
    size25: 100,
    size26: 120,
    size27: 144,
    size28: 168,
    size29: 192,
    size30: 240,
    size31: 360,
    size32: 480,
    size33: 720,
    size34: 960,
    size35: 1200,
    size36: 1440,
    size37: 2880,
  },
  breakpoints: ['481px', '737px', '977px', '1241px'],
};
